.InfoBox {
  word-wrap: break-word;
}

.Headshot {
  max-width: 70%;
}

.ImageBox {
  height: 8em;
}

.TextBox {
  text-align: left;
}

.Lister {
  text-align: left;
}

.App {
  text-align: center;
  margin-top: 1em;
  margin-bottom: 1em;
  background-color: #F8F0C6;
}

.ElevatorPitch {
  text-align: left;
}

.DividerText {
  font-style: italic;
  font-size: 12px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.AboutMe {
  text-align: left;
}